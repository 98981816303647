import React, {useEffect, useState} from 'react'
import Header from './components/Header'
import Home from "./components/Home"
import Page from './components/Page'
import Contact from "./components/Contact"
import Overview from "./components/Overview"
import Benefits from "./components/Benefits"
import Cases from "./components/Cases"
import Footer from "./components/Footer"
import Partners from "./components/Partners"
import AboutCookies from "./components/AboutCookies"
import CookieConsentDialog from './components/CookieConsentDialog'
import ReactGA from "react-ga4"

export default () => {
  const [ showCookiePolicy, setShowCookiePolicy ] = useState(false)
  const [ showCookieDialog, setShowCookieDialog ] = useState(false)

  useEffect(() => {
    const cookiesAllowed = localStorage.getItem("cookiesAllowed")
    if (cookiesAllowed === null) {
      window.setTimeout(() => setShowCookieDialog(true), 2000)
    }
    initGA4(cookiesAllowed === 'true')
  }, [])

  const handleAllowCookies = () => {
    localStorage.setItem('cookiesAllowed', 'true')
    setShowCookieDialog(false)
    setShowCookiePolicy(false)
    console.log('Allowing GA4 cookies for analytics')
    ReactGA.gtag("consent", "update", {
      analytics_storage: "granted"
    })
  }

  const handleDenyCookies = () => {
    localStorage.setItem('cookiesAllowed', 'false')
    setShowCookieDialog(false)
    setShowCookiePolicy(false)
    console.log('Denying GA4 cookies from analytics')
    ReactGA.gtag("consent", "update", {
      analytics_storage: "denied"
    })
  }

  const initGA4 = (allowCookies) => {
    if (!allowCookies) {
      console.log('Denying all GA4 consent')
      ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      })
    }
    console.log('Initializing GA4')
    ReactGA.initialize([
      { trackingId: "AW-16803364917" }
    ])
  }

  return (
    <div className="App">
      <Header>
        <Page id="home" first={true}>
          <Home/>
        </Page>
        <Page id="benefits" backgroundColor="#dddef2">
          <Benefits/>
        </Page>
        <Page id="overview" backgroundColor="#f5d6d6">
          <Overview/>
        </Page>
        <Page id="cases" backgroundColor="#54577d">
          <Cases/>
        </Page>
        <Page id="contact" backgroundColor='#f05a73' last={true}>
          <Contact/>
        </Page>
      </Header>
      <Partners/>
      <Footer setShowCookiePolicy={setShowCookiePolicy}/>
      <AboutCookies
        visible={showCookiePolicy}
        onAllow={handleAllowCookies}
        onDeny={handleDenyCookies}
      />
      {showCookieDialog && (
        <CookieConsentDialog
          setShowCookiePolicy={setShowCookiePolicy}
          onAllow={handleAllowCookies}
          onDeny={handleDenyCookies}
        />
      )}
    </div>
  )
}
