import React, {useState, useRef, useEffect} from 'react'
import Logo from '../images/logo.svg'
import MenuImage from '../images/menu.svg'
import styles from '../styles/Header.module.css'
import ReactGA from 'react-ga4'

export default ({children}) => {
  const [page, _setPage] = useState('home')
  const [hoverMenu, setHoverMenu] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const pageRef = useRef(page)
  let refs = {}

  const setPage = (val) => {
    pageRef.current = val
    _setPage(val)
    // Conversion if user scrolls to bottom page for contact info
    if (val === 'contact') {
      // Not sure which one to use
      //ReactGA.event('ads_conversion_Contact_1')
      ReactGA.event('conversion', {'send_to': 'AW-16803364917/3q72CPbbgPIZELWAvMw-'})
    }
  }

  useEffect(() => {
    Object.keys(refs).forEach(id => {
      if(document.location.pathname.includes(id)){
        refs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setPage(id)
      }
    })
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const handleScroll = () => {
    let rid = 'home'
    Object.keys(refs).forEach(id => {
      if (document.body.scrollTop > refs[id].current.parentNode.offsetTop - 300 ||
        document.documentElement.scrollTop > refs[id].current.parentNode.offsetTop - 300)
        rid = id
    })
    if (pageRef.current !== rid){
      setPage(rid)
      window.history.replaceState({page: rid}, rid, '/' + rid)
    }
    if (document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50)
      setHoverMenu(true)
    else
      setHoverMenu(false)
  }

  const handleMenuClick = (ev) => {
    setShowMenu(false)
    Object.keys(refs).forEach(id => {
      //console.log(id)
      if (id === ev.target.name){
        refs[id].current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    })
  }

  const menuItemClass = (key) => {
    let ret = [styles.menuItem]
    if (page === key)
      ret.push(styles.currentMenuItem)
    return ret.join(' ')
  }

  const headerClass = () => {
    let ret = [styles.header]
    if (hoverMenu)
      ret.push(styles.headerHovering)
    return ret.join(' ')
  }

  return (
    <React.Fragment>
      <div className={headerClass()}>
        <button className={[styles.menuItem, styles.toggleMenu].join(' ')} onClick={() => setShowMenu(!showMenu)}>
          <img src={MenuImage} alt="Toggle menu"/>
        </button>
        <div className={[styles.menu, showMenu && styles.menuVisible].join(' ')}>
          <button className={menuItemClass("home")} name="home" onClick={handleMenuClick}>
            Etusivu
          </button>
          <button className={menuItemClass("benefits")} name="benefits" onClick={handleMenuClick}>
            Mitä ja miksi?
          </button>
          <button className={menuItemClass("overview")} name="overview" onClick={handleMenuClick}>
            Ominaisuudet
          </button>
          <button className={menuItemClass("cases")} name="cases" onClick={handleMenuClick}>
            Caset
          </button>
          <button className={menuItemClass("contact")} name="contact" onClick={handleMenuClick}>
            Yhteys
          </button>
        </div>
        <div className={styles.logoContainer}>
          <img className={hoverMenu ? styles.logoHover : styles.logo} src={Logo} alt="SensorMonitor logo"/>
        </div>
      </div>
      {React.Children.map(children, (element, idx) => {
        const ref = useRef(null)
        refs[element.props.id] = ref
        return React.cloneElement(element, { ref: ref })
      })}
    </React.Fragment>
  )
}